import { createApp, h } from 'vue'
import store from '@/store'
import Login from '@/pages/login/Login.vue'

document.addEventListener('DOMContentLoaded', () => {
    const id = 'login'
    const el = document.getElementById(id)

    if (el) {
        const app = createApp({
            render: () => h(Login),
        })
        app.use(store)
        app.mount('#' + id)
    }
})
