import client from '@/api/client'

export async function loginUser(
    email: string,
    password: string,
    mfa?: string,
    recaptchaResponse?: string,
    mfaRememberDevice?: boolean,
    recoveryCode?: string
) {
    const formData = new FormData()
    formData.append('email', email.toString())
    formData.append('password', password.toString())
    if (mfa !== undefined) {
        formData.append('mfa', mfa.toString())
    }
    if (recoveryCode !== undefined && recoveryCode !== '') {
        formData.append('recoveryCode', recoveryCode.toString())
    }
    if (mfaRememberDevice !== undefined) {
        formData.append('mfaRememberDevice', mfaRememberDevice.toString())
    }
    if (recaptchaResponse !== undefined) {
        formData.append('g-recaptcha-response', recaptchaResponse.toString())
    }
    return client.post('/login', formData)
}

export function registerUser(params) {
    return client.post(`/ajax/register`, params, { baseURL: '/' })
}
