<template>
    <div class="login">
        <div class="login-image">
            <img src="/img/login/cover-img.jpg" alt="Nicely Done" />
        </div>
        <div class="login-form" @keyup.enter="login">
            <CredentialsForm :logo="loginData.logo">
                <template v-if="mfaEnforce">
                    <div v-if="mfaSuccess">
                        <MFASetupOk
                            :is-loading="isLoading"
                            :codes="recoveryCodes"
                            :from-mfa-setup="true"
                            @onDone="onDone"
                            @onDownload="onDownloadRecoveryCodes"
                        />
                    </div>
                    <div v-else>
                        <MFASetup
                            :email="email"
                            :password="password"
                            :qr-url="mfaQrCode"
                            :mfa-secret="mfaSecret"
                            @mfaUpdate="mfaValidateLogin"
                            @mfaCancel="mfaCancel"
                        />
                    </div>
                </template>
                <template v-else-if="!mfaLogin">
                    <template v-if="loginData.emailLoginEnabled">
                        <div class="heading-container">
                            <div class="heading">Sign In</div>
                            <div
                                v-if="loginData.canSelfRegister"
                                class="button"
                            >
                                <a href="/register">
                                    <BaseButton white bordered>Register</BaseButton>
                                </a>
                            </div>
                        </div>
                        <Input
                            ref="email"
                            v-model="email"
                            title="Email"
                            placeholder="Email"
                            :regex="/.+@.+\..+/"
                            :required="true"
                            :err-msg="'Valid email is required'"
                        />
                        <Input
                            ref="password"
                            v-model="password"
                            title="Password"
                            placeholder="Password"
                            type="password"
                            :required="true"
                            :err-msg="'Invalid Password'"
                        />
                        <GrecaptchaBox
                            v-if="loginData.hasRecaptcha"
                            ref="grecaptcha"
                            :sitekey="loginData.recaptcha"
                        />
                        <BaseButton
                            class="sign-in-button"
                            new-primary
                            full
                            large
                            :click="login"
                            >Sign In</BaseButton>
                        <div class="forgot">
                            <a href="/password">Forgot Password</a>
                        </div>
                    </template>

                    <template
                        v-if="
                            loginData.emailLoginEnabled &&
                            loginData.hasOauthLogins
                        "
                    >
                        <div class="or">
                            <div class="line"></div>
                            <div class="or-text">or</div>
                            <div class="line"></div>
                        </div>
                    </template>

                    <BaseButton
                        v-if="loginData.googleUrl"
                        :click="() => redirectTo(loginData.googleUrl)"
                        class="sign-in-button"
                        secondary
                        bordered
                        full
                        large
                    >
                        <img
                            class="oauth-icon"
                            src="/img/login/google-oauth-icon.svg"
                        />
                        Sign in with Google
                    </BaseButton>
                    <BaseButton
                        v-if="loginData.oktaUrl"
                        :click="() => redirectTo(loginData.oktaUrl)"
                        class="sign-in-button"
                        secondary
                        bordered
                        full
                        large
                    >
                        <img
                            class="oauth-icon"
                            src="/img/login/okta-oauth-icon.png"
                        />
                        Sign in with Okta
                    </BaseButton>
                    <BaseButton
                        v-if="loginData.microsoftUrl"
                        :click="() => redirectTo(loginData.microsoftUrl)"
                        class="sign-in-button"
                        secondary
                        bordered
                        full
                        large
                    >
                        <img
                            class="oauth-icon"
                            src="/img/login/microsoft-oauth-icon.png"
                        />
                        Sign in with Microsoft 365
                    </BaseButton>
                    <BaseButton
                        v-if="loginData.samlSsoUrl"
                        :click="() => redirectTo(loginData.samlSsoUrl)"
                        class="sign-in-button"
                        new-primary
                        full
                        large
                        >{{ loginData.samlSsoName }}</BaseButton>
                </template>
                <template v-else>
                    <!-- If user hs no more recovery codes, show new recovery codes if they were generated -->
                    <template v-if="mfaSuccess && recoveryCodes">
                        <MFASetupOk
                            :is-loading="isLoading"
                            :codes="recoveryCodes"
                            :from-mfa-setup="false"
                            @onDone="onDone"
                            @onDownload="onDownloadRecoveryCodes"
                        />
                    </template>
                    <template v-else-if="isMfaRecoveryPage">
                        <MFAVerifyRecoveryCode
                            @onBack="mfaCancel"
                            @onSubmit="onVerifyRecoveryCode"
                        />
                    </template>
                    <template v-else>
                        <div class="heading">Enter Multi-Factor Code</div>
                        <Input
                            ref="mfa"
                            v-model="mfa"
                            title="Multi-Factor Authentication Code"
                            type="number"
                            placeholder="6 digit MFA code"
                            :required="true"
                            :err-msg="'Code cannot be empty'"
                        />
                        <Checkbox
                            class="remember-device-checkbox"
                            :checked="mfaRememberDevice"
                            @change="onChangeRememberDevice"
                            >Remember this device for 30 days</Checkbox>
                        <BaseButton
                            class="sign-in-button"
                            new-primary
                            full
                            large
                            :click="login"
                            >Sign In</BaseButton>
                        <div class="error">{{ error }}</div>
                        <div class="forgot">
                            <a href="/password">Forgot Password</a>
                        </div>
                        <div class="lost-access-legend">
                            <a @click="onLostAuthenticatorLink">Lost Access To My Authenticator</a>
                        </div>
                    </template>
                </template>
                <div class="error">{{ error }}</div>

                <div class="ask-logo">
                    <a href="https://www.asknicely.com" target="_blank">
                        <img
                            src="/img/asknicely_logo_grey.svg"
                            alt="AskNicely"
                        />
                    </a>
                </div>
            </CredentialsForm>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import CredentialsForm from '@/components/CredentialsForm.vue'
import Input from '@/components/Input.vue'
import BaseButton from '@/components/BaseButton.vue'
import { LoginData } from '@/pages/login/entities/login'
import { loginUser } from '@/api/login'
import GrecaptchaBox from '@/components/GrecaptchaBox.vue'
import Checkbox from '@/components/Rain/Inputs/Checkbox.vue'
import MFASetup from '@/components/Settings/MFASetup.vue'
import MFASetupOk from '@/pages/login/MFASetupOk.vue'
import MFAVerifyRecoveryCode from '@/pages/login/MFAVerifyRecoveryCode.vue'

type Input = typeof Vue & { hasError: boolean; hasFocused: boolean }
const DISABLED_SUBDOMAINS: string[] = ['asknicely']

@Component({
    components: {
        MFAVerifyRecoveryCode,
        MFASetupOk,
        MFASetup,
        Checkbox,
        GrecaptchaBox,
        BaseButton,
        Input,
        CredentialsForm,
    },
})
export default class Login extends Vue {
    public email = ''
    public password = ''
    public mfa = ''
    public mfaRememberDevice = false
    public loginData?: LoginData
    public error = ''
    public mfaLogin = false
    public mfaEnforce = false
    public mfaQrCode = ''
    public mfaSecret = ''
    public mfaSuccess = false
    public isMfaRecoveryPage = false
    public recoveryCodes: string[] = []
    public recoveryCode = ''
    public isLoading = true
    public redirectUrl = ''

    public async beforeMount() {
        // todo: add a check to see if the user is logged in via API/cookie/session
        const dataEl = document.getElementById('login-data')
        const dataJson = dataEl && dataEl.getAttribute('data-json')
        const data: LoginData = dataJson && JSON.parse(dataJson)

        this.loginData = data
        this.error = data.error
    }

    public async login() {
        this.error = ''
        let recaptchaResponse = ''

        if (this.loginData !== undefined && this.loginData.hasRecaptcha) {
            const grecaptcha = this.$refs.grecaptcha as GrecaptchaBox
            recaptchaResponse = grecaptcha.getResponse()
            if (recaptchaResponse.length < 1) {
                this.error = 'Please complete reCAPTCHA challenge'
                return
            }
        }

        if (!this.mfaLogin && !this.mfaEnforce) {
            (this.$refs.email as Input).hasFocused = true
            ;(this.$refs.password as Input).hasFocused = true
        }

        if (this.password === '' || !this.email.match(/.+@.+\..+/)) {
            return
        }

        // Don't allow empty mfa code submission
        if (this.mfaLogin && this.mfa === '') {
            return
        }

        try {
            const { data } = await loginUser(
                this.email,
                this.password,
                this.mfaLogin || this.mfaEnforce ? this.mfa : undefined,
                this.loginData !== undefined && this.loginData.hasRecaptcha
                    ? recaptchaResponse
                    : undefined,
                this.mfaLogin ? this.mfaRememberDevice : undefined,
                this.recoveryCode
            )
            if (data.mfa) {
                this.mfaLogin = true
                this.$nextTick(() => {
                    (this.$refs.mfa as HTMLInputElement).focus()
                })
            } else if (data.mfaEnforce) {
                this.mfaEnforce = true
                this.mfaQrCode = data.codeUrl
                this.mfaSecret = data.mfaSecret
            } else if (data.recoveryCodes) {
                this.recoveryCodes = data.recoveryCodes
                this.mfaSuccess = true
                this.isLoading = false
                this.redirectUrl = data.redirect
            } else {
                this.redirectTo(data.redirect)
            }
        } catch (e: any) {
            this.error = e.response.data.error
        }
    }

    public async verifyRecoveryCode() {
        try {
            this.error = ''
            let recaptchaResponse = ''

            const { data } = await loginUser(
                this.email,
                this.password,
                this.mfaLogin || this.mfaEnforce ? this.mfa : undefined,
                this.loginData !== undefined && this.loginData.hasRecaptcha
                    ? recaptchaResponse
                    : undefined,
                this.mfaLogin ? this.mfaRememberDevice : undefined,
                this.recoveryCode
            )

            if (data.recoveryCodes) {
                this.recoveryCodes = data.recoveryCodes
                this.mfaSuccess = true
                this.isLoading = false
                this.redirectUrl = data.redirect
            } else {
                this.redirectTo(data.redirect)
            }
        } catch (e: any) {
            this.error = e.response.data.error
        }
    }

    public mfaValidateLogin(mfaCode: string) {
        if (!mfaCode || mfaCode.length !== 6) {
            this.error = 'Please enter your 6 digit MFA code'
            return
        }
        this.mfa = mfaCode
        this.login()
    }

    public onDownloadRecoveryCodes() {
        const dataAsString = this.recoveryCodes.join('\n')
        const blob = new Blob([dataAsString], { type: 'text/plain' })
        const url = window.URL.createObjectURL(blob)

        const link = document.createElement('a')
        link.href = url
        link.download = 'recovery-codes.txt'
        document.body.appendChild(link)

        link.click()

        document.body.removeChild(link)
        window.URL.revokeObjectURL(url)
    }

    public created() {
        window.addEventListener('keydown', (e) => {
            const isCommandG = (e.metaKey || e.ctrlKey) && e.key === 'g'
            const subDomain = window.location.host.split('.')[0]
            const notDisabled = DISABLED_SUBDOMAINS.indexOf(subDomain) === -1
            if (
                notDisabled &&
                isCommandG &&
                this.loginData !== undefined &&
                this.loginData.enabledGLogin
            ) {
                e.preventDefault()
                this.redirectTo(this.loginData.asknicelyLoginUrl)
            }
        })
    }

    public mfaCancel() {
        this.error = ''
        this.mfaEnforce = false
        this.mfaQrCode = ''
        this.mfaSecret = ''
        this.isMfaRecoveryPage = false
        this.recoveryCode = ''
    }

    public redirectTo(link: string) {
        return location.replace(link)
    }

    public onChangeRememberDevice(value: boolean): void {
        this.mfaRememberDevice = value
    }

    public onLostAuthenticatorLink(): void {
        this.isMfaRecoveryPage = true
    }

    public onVerifyRecoveryCode(recoveryCode: string) {
        if (!recoveryCode) {
            this.error = 'Recovery code required.'
            return
        }
        this.recoveryCode = recoveryCode
        this.verifyRecoveryCode()
    }

    public onDone() {
        this.redirectTo(this.redirectUrl)
    }
}
</script>

<style lang="less" scoped>
@import '../../styles/palette';

@media screen and (max-width: 1024px) {
    .login-image {
        display: none;
    }
}

.login {
    display: flex;
    background: #fff;
    position: relative;
    height: 100%;
    max-width: 1024px;
    margin: 0 auto;

    .login-image {
        margin: 87px 58px;

        img {
            width: 477px;
        }
    }

    .login-form {
        margin: 0 auto;

        .input-container {
            :deep(::-webkit-outer-spin-button),
            :deep(::-webkit-inner-spin-button) {
                display: none;
                -webkit-appearance: none;
                margin: 0;
            }

            :deep(input[type='number']) {
                -moz-appearance: textfield; /* Firefox */
            }
        }

        .remember-device-checkbox {
            margin-top: 14px;
        }

        .sign-in-button {
            margin-top: 14px;
        }

        .ask-logo {
            margin-top: 22px;
            text-align: center;

            img {
                height: 20px;
            }
        }

        .heading {
            margin-top: 22px;
            font-size: 20px;
            font-weight: 500;
        }

        .heading-container {
            display: flex;
            align-items: center;

            .heading {
                flex: 1;
                margin-top: 0;
            }
        }

        .error {
            color: @red;
            font-size: 12px;
            margin-top: 8px;
        }

        .forgot,
        .lost-access-legend {
            text-align: right;

            a {
                font-size: 13px;
                color: @grey40;
                text-decoration: none;
            }
        }

        .or {
            display: flex;
            text-align: center;
            color: @grey40;
            padding-top: 14px;

            .line {
                width: 120px;
                border-bottom: 1px solid @grey40;
                position: relative;
                top: -8px;
            }

            .or-text {
                padding: 0 8px;
            }
        }

        .oauth-icon {
            position: relative;
            top: 3px;
            left: -2px;
        }
    }
}
.lost-access-legend {
    line-height: 18px;
    cursor: pointer;
}
</style>
