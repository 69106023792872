<template>
    <div id="g-recaptcha" :data-sitekey="sitekey" data-badge="inline"></div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import {
    getGrecaptchaResponse,
    injectGrecaptchaScript,
    render,
} from '@/utils/grecaptcha'

@Component({})
export default class GrecaptchaBox extends Vue {
    @Prop({ type: String, required: true }) public sitekey!: string

    public recaptchaId = 0

    public async beforeMount() {
        await injectGrecaptchaScript()
        await this.renderGrecaptcha()
    }

    public async renderGrecaptcha() {
        const recaptcha = document.getElementById('g-recaptcha') as HTMLElement
        this.recaptchaId = render(recaptcha, this.sitekey)
    }

    public getResponse() {
        return getGrecaptchaResponse(this.recaptchaId)
    }
}
</script>

<style lang="less" scoped>
#g-recaptcha {
    margin-top: 15px;
    transform: scale(0.85);
    transform-origin: 0 0;
}
</style>
