export interface GrecaptchaEnterprise {
    ready(callback: () => void): void
    execute(siteKey: string, config: { action: string }): Promise<string>
    getResponse(recaptchaId): string
    render(recaptcha: HTMLElement, config: { sitekey: string }): number
}

export interface Grecaptcha extends GrecaptchaEnterprise {
    enterprise?: GrecaptchaEnterprise
}

declare let grecaptcha: Grecaptcha | undefined

export function getCaptchaToken(recaptchaSitekey: string, action: string) {
    return new Promise<string>((resolve) => {
        if (typeof grecaptcha === 'undefined') {
            return resolve('')
        }

        const finalGrecaptcha = grecaptcha.enterprise
        if (typeof finalGrecaptcha === 'undefined') {
            return resolve('')
        }

        finalGrecaptcha.ready(() => {
            if (grecaptcha) {
                finalGrecaptcha
                    .execute(recaptchaSitekey, { action })
                    .then(resolve)
            }
        })
    })
}

export function getGrecaptchaResponse(recaptchaId: number): string {
    if (typeof grecaptcha === 'undefined') {
        return ''
    }
    return grecaptcha.getResponse(recaptchaId)
}

export function render(recaptchaElement: HTMLElement, sitekey: string): number {
    if (typeof grecaptcha === 'undefined') {
        return 0
    }
    return grecaptcha.render(recaptchaElement, { sitekey: sitekey })
}

export function injectGrecaptchaScript(): Promise<boolean> {
    const recaptchaScript = document.createElement('script')
    recaptchaScript.src = `https://www.google.com/recaptcha/api.js?render=explicit`
    recaptchaScript.async = true
    recaptchaScript.defer = true
    document.head.appendChild(recaptchaScript)
    return new Promise<boolean>((resolve) => {
        recaptchaScript.onload = () => {
            const win = window as any
            win.grecaptcha.ready(() => {
                return resolve(true)
            })
        }
    })
}
