<template>
    <div>
        <div class="uk-grid">
            <div id="qr-code-image" class="uk-width-1-2">
                <img
                    v-if="qrUrl && qrUrl.length"
                    id="mfa-code"
                    :src="qrUrl"
                    width="200"
                    height="200"
                    alt="mfa-code"
                />
            </div>
            <div class="uk-width-1-2 uk-margin-top">
                <p>
                    Please scan the QR code with your authenticator app. <br />
                    To verify your setup, please enter the current 6-digit
                    authenticator code.
                </p>
                <Input
                    ref="mfaCodeInput"
                    v-model="mfaCode"
                    class="code-input"
                    type="number"
                    title="Your 6 digit code"
                    placeholder="Your 6 digit code"
                    maxlength="6"
                    :required="true"
                    :err-msg="'Please enter your 6 digit code'"
                    :regex="/\d{6}/"
                />
            </div>
            <div class="uk-width-1-1 uk-margin-top uk-text-small">
                <p>
                    No QR code scanner? <br />
                    Please enter this secret key below into your preferred MFA
                    app.
                    <code class="mfa-secret-key">{{ mfaSecret }}</code>
                </p>
            </div>
        </div>

        <div id="button-container" class="uk-text-right">
            <a
                class="button uk-button back-button"
                @click="$emit('mfaCancel')"
                >{{ cancelText }}</a>
            <a
                class="button primary verify-mfa-btn uk-button"
                @click="verifyMfaCode"
                >Verify MFA</a>
        </div>
    </div>
</template>
<script setup lang="ts">
import { ref } from 'vue'
import Input from '@/components/Input.vue'

declare const UIkit

interface Props {
    qrUrl: string
    mfaSecret: string
    cancelText?: string
}
const props = withDefaults(defineProps<Props>(), {
    qrUrl: '',
    mfaSecret: '',
    cancelText: 'Cancel',
})

const mfaCode = ref('')

const emit = defineEmits(['mfaUpdate', 'mfaCancel'])

async function verifyMfaCode() {
    emit('mfaUpdate', mfaCode.value)
}
</script>

<style scoped lang="less">
@import '~@/styles/rain/colour.less';
@import '~@/styles/rain/variables.less';

.code-input {
    :deep(::-webkit-outer-spin-button),
    :deep(::-webkit-inner-spin-button) {
        display: none;
        -webkit-appearance: none;
        margin: 0;
    }

    :deep(input[type='number']) {
        -moz-appearance: textfield; /* Firefox */
    }
}
#button-container {
    display: flex;
    justify-content: flex-start;
    gap: 15px;
    margin-top: 20px;
}

.verify-mfa {
    float: right;
}
.verify-mfa-btn {
    width: 120px;
}
.back-button {
    width: 50px;
}
.button {
    font-size: 12px;
    border: 1px solid #e1dfdd;
    text-align: center;
    padding: 3px 15px;
}
.primary {
    background-color: @primaryBlue;
    color: @white;
}

.mfa-secret-key {
    margin: 8px 0;
    text-align: center;
    width: 100%;
}
#qr-code-image {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
