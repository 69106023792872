<template>
    <div id="mfa-success-container">
        <div class="uk-grid">
            <div class="uk-width-1-2">
                <img
                    src="/img/login/mfa-key.svg"
                    width="37"
                    height="39"
                    alt="success-icon"
                />
            </div>
            <div class="uk-width-1-2 uk-margin-top">
                <Typography variant="h4" component="h4" class="title-container">Verify Recovery Code</Typography>
            </div>
            <div class="uk-width-1-1 uk-margin-top uk-text-small">
                <p>
                    Please enter one of your
                    <strong>recovery codes.</strong>
                </p>
                <Input
                    ref="recoveryCodeInput"
                    v-model="recoveryCode"
                    class="codeInput"
                    title="Enter your recovery code"
                    placeholder="Enter your recovery code"
                    :maxlength="13"
                    :required="true"
                    :err-msg="'Please enter your recovery code'"
                />
            </div>
        </div>

        <div id="button-container">
            <a class="button uk-button" @click="$emit('onBack')">Back</a>
            <a class="uk-button button primary" @click="sendRecoveryCode">Submit</a>
        </div>
    </div>
</template>
<script setup lang="ts">
import Input from '@/components/Input.vue'
import { ref } from 'vue'
import Typography from '@/components/Rain/Typography/Typography.vue'

interface Props {
    onBack: Function
    onSubmit: Function
}
const emit = defineEmits(['onBack', 'onSubmit'])
const recoveryCode = ref('')

const sendRecoveryCode = () => {
    emit('onSubmit', recoveryCode.value)
}
</script>

<style scoped lang="less">
@import '~@/styles/rain/colour.less';
@import '~@/styles/rain/variables.less';

#mfa-success-container {
    padding-top: 20px;
}
#mfa-success-container p {
    font-size: 14px;
    line-height: 20px;
}
#button-container {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    margin-top: 20px;
}
.button {
    font-size: 12px;
    border: 1px solid #e1dfdd;
    border-radius: 4px;
    text-align: center;
    padding: 3px 15px;
}
.primary {
    background-color: @primaryBlue;
    border: 1px solid @primaryBlue;
    color: @white;
}
#recovery-codes {
    display: flex;
    flex-wrap: wrap;
    min-height: 100px;
    border: 1px dashed #2e5bff;
    background-color: #2e5bff1a;
}

#recovery-codes p {
    flex: 0 0 50%; /* Set each column to take up 50% of the container width */
    box-sizing: border-box; /* Include padding and border in the box sizing */
    padding: 5px; /* Add padding for spacing between columns */
    margin: 0;
    color: #2e5bff;
    font-family: 'American Typewriter', monospace;
}
.mfa-title {
    font-size: 20px;
}
.uk-margin-top {
    margin-top: 10px;
}
</style>
