import { openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3a4cb226"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-sitekey"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: "g-recaptcha",
    "data-sitekey": _ctx.sitekey,
    "data-badge": "inline"
  }, null, 8, _hoisted_1))
}