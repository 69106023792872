import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-02661889"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "credentials-form" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "company-logo" }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          src: _ctx.logoUrl,
          alt: "Logo"
        }, null, 8, _hoisted_4)
      ])
    ]),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ]))
}