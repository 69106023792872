<template>
    <div class="credentials-form">
        <div class="header">
            <div class="company-logo">
                <img :src="logoUrl" alt="Logo" />
            </div>
        </div>
        <slot />
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { getCDNUrl } from '@/utils/cdn'

@Component({})
export default class CredentialsForm extends Vue {
    @Prop({ type: String, default: '' }) public logo?: string

    get logoUrl() {
        return getCDNUrl(this.logo)
    }
}
</script>

<style lang="less" scoped>
.credentials-form {
    width: 350px;
    padding: 32px;
    box-sizing: border-box;

    .header {
        display: flex;
        justify-content: space-between;

        .company-logo {
            img {
                height: 36px;
            }
        }
    }
}
</style>
